import React, { useEffect, useRef } from "react";
import { Language, useLanguageContext } from "../../Context/LanguageContext";

function Example() {
  const mounted = useRef(false);

  const { lang } = useLanguageContext();

  useEffect(() => {
    if (mounted.current) {
      return;
    }
    const script = document.createElement("script");
    script.src = "https://app.cloudpano.com/public/shareScript.js";
    script.async = true;
    script.setAttribute("data-short", "oK0ZhyFDa");
    script.setAttribute("height", "600px");
    document.body.appendChild(script);

    const x = document.getElementById("oK0ZhyFDa");
    if (x) {
      x.innerHTML = "";
      x.appendChild(script);
    }
    mounted.current = true;
  }, []);
  return (
    <div
      className="w-full py-16 bg-360-grey-500 flex flex-col justify-center"
      id="idea"
    >
      <div className="w-5/6 self-center">
        <span className="text-5xl text-white font-bold bebas">
          <span className="text-360-yellow-500">
            {lang === Language.DE && "Interaktives Beispiel"}
            {lang === Language.FR && "Exemple interactif"}
            {lang === Language.EN && "Interactive example"}
          </span>
          &nbsp;
          {lang === Language.DE && "einer Aufnahme"}
          {lang === Language.FR && "d'un enregistrement"}
          {lang === Language.EN && "of a recording"}
        </span>
      </div>

      <div className="pt-8" id="oK0ZhyFDa" />
    </div>
  );
}

export default Example;
