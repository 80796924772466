import React from "react";
import "./App.scss";
import logo from "./Assets/Images/colored-logo-bg.svg";
import Benefits from "./Components/Benefits/Benefits";
import Example from "./Components/Example/Example";
import Header from "./Components/Header/Header";
import Idea from "./Components/Idea/Idea";
import Services from "./Components/Services/Services";
import Slogan from "./Components/Slogan/Slogan";
import Who from "./Components/Who/Who";
import Youtube from "./Components/Youtube/Youtube";
import { Language, useLanguageContext } from "./Context/LanguageContext";

function App(): JSX.Element {
  const { lang, setLang } = useLanguageContext();
  return (
    <div className="flex flex-col ">
      <div className="w-5/6 mt-3 self-center">
        <Header />
        <div className="mt-2 flex gap-2 flex-wrap self-end justify-end">
          <span
            className={`language ${lang === Language.DE && "selected"}`}
            onClick={() => setLang(Language.DE)}
          >
            DE
          </span>
          <span
            className={`language ${lang === Language.FR && "selected"}`}
            onClick={() => setLang(Language.FR)}
          >
            FR
          </span>
          <span
            className={`language ${lang === Language.EN && "selected"}`}
            onClick={() => setLang(Language.EN)}
          >
            EN
          </span>
        </div>

        <div className="mt-8 mb-8 w-full gap-3 flex justify-around items-center break-words flex-wrap">
          <img
            src={logo}
            className="w-[12rem] sm:w-[12rem] lg:w-[24rem] xl:w-[36rem] 2xl:w-[48rem] self-center"
            alt="logo"
          />
          <div className="text-white bebas text-2xl md:text-3xl xl:text-6xl flex flex-col">
            <span>VIRTUAL DOCUMENTATION</span>
            <span className="text-360-yellow-500">YOU CAN COUNT ON.</span>
          </div>
        </div>
      </div>

      <Idea />
      <Youtube embedId="gdMi7rJ9OyY" />

      <Example />
      <div className="w-5/6 self-center grid md:grid-cols-2 justify-start md:gap-16 items-start ">
        <Benefits />
        <Who />
      </div>

      <Services />
      <Slogan />
      <div className="w-full self-center bg-360-gray-500 flex justify-center">
        <div className="flex flex-wrap gap-3 w-5/6 self-center justify-between md:gap-16 items-center py-3 text-white">
          <div>
            &copy; 2022 3Sixty S.à r.l. &middot; TVA Nummer N° TVA LU34140501
            &middot; RCS Nummer: RCS B269286 &middot; Gérant: Pit Spautz <br />
          </div>
          <small>Design by Bill Brinck</small>
        </div>
      </div>
    </div>
  );
}

export default App;
