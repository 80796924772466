import React from "react";
import { Language, useLanguageContext } from "../../Context/LanguageContext";

function Who() {
  const { lang } = useLanguageContext();
  return (
    <div
      className="py-16 bg-360-gray-500 flex flex-col justify-center"
      id="who"
    >
      <div className="self-center">
        <span className="w-full text-5xl text-white font-bold bebas">
          <span className="text-360-yellow-500">
            {lang === Language.DE && "AN WEN"}
            {lang === Language.FR && "À QUI"}
            {lang === Language.EN && "TO WHOM"}
          </span>
          &nbsp;
          {lang === Language.DE && "RICHTET SICH UNSER SERVICE"}
          {lang === Language.FR && "S'ADRESSE NOTRE SERVICE"}
          {lang === Language.EN && "IS OUR SERVICE DIRECTED"}
        </span>
        <div className="text-white mt-3">
          <ul className="hex">
            <li>
              {lang === Language.DE && "Bauherren"}
              {lang === Language.FR && "Maître d'ouvrage"}
              {lang === Language.EN && "Private builder"}
            </li>
            <li>
              {lang === Language.DE && "Bauunternehmer/Promoteur"}
              {lang === Language.FR && "Entrpreneur/promoteur"}
              {lang === Language.EN && "Contractor/Promoteur"}
            </li>
            <li>
              {lang === Language.DE && "Sanierungs- & Renovierungsfirma"}
              {lang === Language.FR &&
                "Entreprise de réhabiliation & de rénovation"}
              {lang === Language.EN && "Renovation & refurbishment company"}
            </li>
            <li>
              {lang === Language.DE && "Architekt und Ingenieur"}
              {lang === Language.FR && "Arhcitecte et ingénieur"}
              {lang === Language.EN && "Architect and engineer"}
            </li>
            <li>
              {lang === Language.DE && "Elektriker"}
              {lang === Language.FR && "Électricien"}
              {lang === Language.EN && "Electrician"}
            </li>
            <li>
              {lang === Language.DE && "Sanitär"}
              {lang === Language.FR && "Plomberie"}
              {lang === Language.EN && "Plumber"}
            </li>
            <li>
              {lang === Language.DE && "Smart Home Installationsservice"}
              {lang === Language.FR && "Service d'installation Smart Home"}
              {lang === Language.EN && "Smart Home installation service"}
            </li>
            <li>
              {lang === Language.DE && (
                <>
                  und alle weiteren, die einen Nutzen für sich in unserem
                  Service finden
                </>
              )}
              {lang === Language.FR && (
                <>
                  et tous les autres qui trouvent un avantage pour leur intérêt
                  dans notre service
                </>
              )}
              {lang === Language.EN &&
                "and all others who find a benefit for themselves in our service"}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Who;
