import React from "react";
import Phone from "../../Assets/Images/phone.jpg";
import { Language, useLanguageContext } from "../../Context/LanguageContext";

function Services() {
  const { lang } = useLanguageContext();
  return (
    <div
      className="w-full py-16 bg-360-yellow-500 flex flex-col justify-center"
      id="services"
    >
      <div className="w-5/6 self-center grid md:grid-cols-[1fr_2fr] gap-8 justify-start">
        <img src={Phone} alt="Phone" className="w-fit md:h-96" />
        <div>
          <span className="text-5xl text-360-gray-500 font-bold bebas">
            {lang === Language.DE &&
              "WIE ERHALTEN SIE DIE DOKUMENTIERUNG VON UNS"}
            {lang === Language.FR &&
              "COMMENT OBTENIR LA DOCUMENTATION DE NOTRE PART"}
            {lang === Language.EN &&
              "HOW DO YOU RECEIVE THE DOCUMENTATION FROM US"}
          </span>
          <div className="text-360-gray-500 mt-3">
            <ul className="hex">
              <li>
                {lang === Language.DE && (
                  <>
                    Sie erhalten ein Din A4 Dokument mit einem QR-Code, das Sie
                    in Ihren Unterlagen aufbewahren und jederzeit mit Ihrem
                    Smartphone oder Tablet scannen können, um auf die
                    Dokumentation zuzugreifen. Dieses Dokument erhalten Sie
                    zusätzlich als PDF-Date, damit Sie es sich so oft wie nötig
                    ausdrucken können.
                  </>
                )}
                {lang === Language.FR && (
                  <>
                    Vous recevrez un document Din A4 avec un code QR que vous
                    pourrez garder dans vos dossiers et scanner à tout moment
                    avec votre smartphone ou votre tablette pour accéder à la
                    documentation. Vous recevrez également ce document sous
                    forme de fichier PDF afin de pouvoir l'imprimer aussi
                    souvent que nécessaire.
                  </>
                )}
                {lang === Language.EN && (
                  <>
                    You will receive a Din A4 document with a QR code that you
                    can keep in your files and scan at any time with your
                    smartphone or tablet to access the documentation. You will
                    also receive this document as a PDF file so that you can
                    print it out as often as necessary.
                  </>
                )}
              </li>
              <li>
                {lang === Language.DE && (
                  <>
                    Sie erhalten einen Permanent-Link, den wir Ihnen per E-Mail
                    zukommen lassen.
                  </>
                )}
                {lang === Language.FR && (
                  <>
                    Vous recevrez un lien permanent que nous vous enverrons par
                    e-mail.
                  </>
                )}
                {lang === Language.EN && (
                  <>
                    You will receive a permanent link which we will send to you
                    by e-mail.
                  </>
                )}
              </li>
              <li>
                {lang === Language.DE && (
                  <>
                    Wir legen einen Backup Ihrer Dokumentation in einer Cloud
                    und auf unserem Server ab, damit Sie die Daten jederzeit von
                    uns anfordern.
                  </>
                )}
                {lang === Language.FR && (
                  <>
                    Nous plaçons une sauvegarde de votre documentation dans un
                    cloud et sur notre serveur afin que vous puissiez nous
                    demander les données à tout moment.
                  </>
                )}
                {lang === Language.EN && (
                  <>
                    We store a backup of your documentation in a cloud and on
                    our server so that you can request the data from us at any
                    time.
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
