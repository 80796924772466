import React from "react";
import { Language, useLanguageContext } from "../../Context/LanguageContext";
import "./Idea.scss";

function Idea() {
  const { lang } = useLanguageContext();
  return (
    <div
      className="w-full py-16 background flex flex-col justify-center"
      id="idea"
    >
      <div className="w-5/6 self-center">
        <span className="text-5xl text-white font-bold bebas">
          {lang === Language.DE && (
            <>
              <span className="text-360-yellow-500">DIE IDEE</span> HINTER
              3SIXTY
            </>
          )}
          {lang === Language.FR && (
            <>
              <span className="text-360-yellow-500">L'IDÉE À L'ORIGINE</span> DE
              3SIXTY
            </>
          )}
          {lang === Language.EN && (
            <>
              <span className="text-360-yellow-500">THE IDEA </span>BEHIND
              3SIXTY
            </>
          )}
        </span>
        <div className="mt-3 text-white flex flex-col gap-6">
          {lang === Language.DE && (
            <>
              <p>
                Ihr Bauprojekt befindet sich im Rohbaustatus oder Sie haben eine
                aufwändige Sanierung oder Renovierung in Angriff genommen.
              </p>
              <p>
                Natürlich gehen Sie davon aus, dass alle Gewerke gewissenhaft
                und fehlerfrei ausgeführt werden. Das ist die Regel, doch was
                ist, wenn es wider Erwarten doch zu einem Schadenfall kommt?
                Vielleicht bereits nach wenigen Monaten oder auch nach vielen
                Jahren bereitet beispielsweise eine Wasserleitung Probleme. Zur
                Beseitigung müssen Sie die entsprechende Stelle in der Wand oder
                unter dem Bodenbelag orten und dazu in der Regel unzählige Pläne
                und Fotos anschauen, miteinander vergleichen und auswerten.
              </p>

              <p>Klingt nervig und zeitaufwändig, oder? </p>

              <p className="font-extrabold">
                Wäre es nicht großartig, wenn man die Fehlersuche in einem
                Schadenfall so kurz wie möglich halten könnte?
              </p>

              <p>Genau hier kommt 3Sixty ins Spiel.</p>
              <p>
                Wir erstellen eine komplette virtuelle Dokumentation Ihrer
                Baustelle im Rohzustand nach dem Verlegen der Leitungen.
              </p>

              <p>
                Wir erstellen diese virtuelle Dokumentation mit Hilfe von
                hochauflösenden HDR 360° Fotos, die den gesamten Zustand des
                Rohbaus festhalten. Sie können jederzeit auf diese Dokumentation
                zugreifen, um das vorliegende Problem schnell, präzise und
                effektiv zu orten.
              </p>

              <p className="font-extrabold">
                So sparen Sie nicht nur Zeit sondern auch Kosten!
              </p>
            </>
          )}

          {lang === Language.FR && (
            <>
              <p>
                Votre project de construction est au stade du gros oeuvre ou
                vous êtes lancé dans une rénovation ou un refraîchissement
                coûteux.
              </p>
              <p>
                Bien entendu, vous partez du principe que tous les corps de
                métier sont exécutés consciencieusement et sans erreur.
              </p>

              <p>
                C'est la règle, mais que se passe-t-il si, contre toute attent,
                un sinistre survient?
              </p>
              <p>
                Après quelques mois ou plusieurs années, une conduite d'eau peut
                par exemple poser des problèmes. Pour y remédier, vous devez
                localiser l'endroit en question dans le mur ou sous le
                revêtement de sol et en général, regarder d'innombrables plans
                et photos, les comparer et les évaluer.
              </p>

              <p className="font-extrabold">
                Cela semble ennuyeux et prend beaucoup de temps, non?
              </p>

              <p>
                Ne serait-il pas formidable de pouvoir réduire au maxium la
                recherche d'erreurs en cas de sinistre?
              </p>
              <p>
                C'est précisément là que 3Sixty intervient. Nouse établissons
                une documentation virtuelle complète de votre chantier à l'état
                brut après la pose des raccordements.
              </p>

              <p>
                Nous créons cette documentation virtualle à l'aide de photos HDR
                360<sup>o</sup> à haute résolution qui enregistrent l'état
                complet du gros oeuvre. Vous pouvez accéder à tout moment à
                cette documentation afin de localiser rapidement, précisément et
                efficacement le problème en question.
              </p>

              <p className="font-extrabold">
                Vous économisez ainsi non seulement du temps, mais aussi des
                coûts!
              </p>
            </>
          )}

          {lang === Language.EN && (
            <>
              <p>
                Your building project is in construction phase or you have
                launched an coomplex redevelopment or renovation project?
              </p>
              <p>
                Of course, you assume that all works will be executed accurately
                and flawlessly.
              </p>

              <p>
                That is the general rule, but{" "}
                <strong>
                  what happens if, unfortunately, a problem appears?
                </strong>
              </p>

              <p>
                Perhaps after just a few months or even after many years, a
                water pipe, for example, causes problems. To fix it, you have to
                locate the problem in the wall or under the flooring and usually
                have to look at countless plans and photos, compare them with
                each other and evaulate them.
              </p>
              <p className="font-bold">
                Sounds annoying and time-consuming, doesn't it?
              </p>

              <p>
                Wouldn't it be great if you could keep troubleshooting as short
                as possible in the event of a problem?
              </p>

              <p>
                This is exactly where 3SIXTY comes in. We create a complete
                virtual documentation of your construction site in its shell
                construction state after the pipes have been laid. We create
                this virtual documentation with the aid of high-resolution HDF
                360<sup>o</sup> photos, capturing the entire state of the
                building site.
              </p>
              <p>
                You can access this documentation at any time to locate the
                problem quickly, precisely and effectively.
              </p>
              <p className="font-bold">
                This not only saves you time, but also money!
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Idea;
