import React from "react";
import Pit from "../../Assets/Images/pit.jpg";
import { Language, useLanguageContext } from "../../Context/LanguageContext";
import Footer from "../Footer/Footer";

function Slogan() {
  const { lang } = useLanguageContext();
  return (
    <div
      className="w-full py-16 bg-360-grey-500 self-center flex flex-col justify-center"
      id="slogan"
    >
      <div className="w-5/6 self-center grid lg:grid-cols-[2fr_1fr] gap-16">
        <div className="text-white">
          <span className="text-5xl text-white font-bold bebas">
            {lang === Language.DE && "WIR SIND BEREIT"}
            {lang === Language.FR && "NOUS SOMMES PRÊTS"}
            {lang === Language.EN && "WE ARE READY"}&nbsp;
            <span className="text-360-yellow-500">
              {lang === Language.DE && "WEN SIE ES SIND"}
              {lang === Language.FR && "QUAND VOUS L'ÊTES"}
              {lang === Language.EN && "WHEN YOU ARE"}
            </span>
            &nbsp;
          </span>
          <div className="text-white mt-3 text-2xl">
            {lang === Language.DE && "Kontakieren Sie uns und"}
            {lang === Language.FR && "Contactez-nous et"}
            {lang === Language.EN && "Contact us and let us"}&nbsp;
            <span className="text-360-yellow-500">
              {lang === Language.DE &&
                "lassen Sie sich unverbindlich informieren."}
              {lang === Language.FR &&
                "laissez-nous vous informer sans engagement."}
              {lang === Language.EN &&
                "present to you our services without any obligation."}
            </span>
            <br />
            {lang === Language.DE &&
              "Gerne nehmen wir uns Zeit, Ihnen die Vorteile unseres Services"}
            {lang === Language.FR &&
              "Nous prendrons volontiers le temps de vous présenter"}
            {lang === Language.EN &&
              "We will be happy to take the time to explain"}
            &nbsp;
            <span className="text-360-yellow-500">
              {lang === Language.DE && "in einem persönlichen Gespräch"}
              {lang === Language.FR &&
                "les avantages de notre service lors d'un entretien personnel."}
              {lang === Language.EN && "the advantages of our service to you"}
            </span>
            &nbsp;
            {lang === Language.DE && "darzulegen."}
            {lang === Language.EN && "in a personal meeting."}
          </div>
          <Footer />
        </div>
        <div className="text-white text-center m-auto lg:text-left">
          <img src={Pit} alt="Pit Spautz" className="max-h-96" />
          <i>
            {lang === Language.DE && "Geschäftsführer"}
            {lang === Language.FR && "Directeur général"}
            {lang === Language.EN && "Managing Director"}
            &nbsp;-&nbsp;Pit Spautz
          </i>
        </div>
      </div>
    </div>
  );
}

export default Slogan;
