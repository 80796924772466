import qrCode from "../../Assets/Images/qr-contact.png";
import { Language, useLanguageContext } from "../../Context/LanguageContext";
import "./Footer.scss";

function Footer(): JSX.Element {
  const { lang } = useLanguageContext();
  return (
    <div className="bg-360-grey-500 " id="footer">
      <div className="py-8 flex flex-col gap-4">
        <div className="gap-6 flex flex-wrap justify-between">
          <div>
            <h1 className="text-5xl text-360-yellow-500 bebas font-bold border-b-4 border-b-360-yellow-500">
              {lang === Language.DE && "Kontakt"}
              {lang === Language.FR && "Contact"}
              {lang === Language.EN && "Contact"}
            </h1>
            <div className="mt-3 flex flex-col font-bold bebas tracking-widest">
              <span className="text-xl">3SIXTY s.à r.l.s</span>

              <span className="text-xl">10, ROUTE DE LUXEMBOURG</span>
              <span className="text-xl">L-6130 JUNGLINSTER</span>
              <a className="link text-xl" href="tel:00352621211173">
                +352 621 211 173
              </a>
              <a className="link text-xl" href="mailto:info@3sixtyvd.lu">
                INFO@3SIXTYVD.LU
              </a>
            </div>
          </div>
          <div className="self-center">
            <a
              href="https://link.v1ce.co.uk/aac8wl/426174"
              target="_blank"
              rel="noreferrer"
            >
              <img src={qrCode} className="h-52" alt="QR Code" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
