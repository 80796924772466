import React from "react";
import { Language, useLanguageContext } from "../../Context/LanguageContext";
import "./Benefits.scss";

function Benefits() {
  const { lang } = useLanguageContext();
  return (
    <div
      className="py-16 bg-360-gray-500 flex flex-col justify-center"
      id="benefits"
    >
      <div className="self-center">
        <span className="text-5xl text-white font-bold bebas">
          <span className="text-360-yellow-500">
            {lang === Language.DE && "DIE VORTEILE"}
            {lang === Language.FR && "LES AVANTAGES"}
            {lang === Language.EN && "THE ADVANTAGES"}
          </span>
          &nbsp;
          {lang === Language.DE && "VON 3SIXTY FÜR SIE"}
          {lang === Language.FR && "DE 3SIXTY POUR VOUS"}
          {lang === Language.EN && "OF 3SIXTY FOR YOU"}
        </span>
        <div className="text-white mt-3">
          <ul className="hex">
            <li>
              {lang === Language.DE && (
                <>
                  Eine einfache und komplette DokumentationIhres Bauprojekts in
                  digitaler Form
                </>
              )}
              {lang === Language.FR && (
                <>
                  Une documentation simple et complète de votre projet de
                  construction sous form électronique
                </>
              )}
              {lang === Language.EN && (
                <>
                  A simple and complete documentation of your construction
                  project in digital form
                </>
              )}
            </li>
            <li>
              {lang === Language.DE && <>Jederzeit verfügbar</>}
              {lang === Language.FR && <>Disponible à tout moment</>}
              {lang === Language.EN && <>Available at any time</>}
            </li>
            <li>
              {lang === Language.DE && (
                <>
                  <div>
                    Schnelle Problemfindungdank der hochauflösenden 360
                    <sup>o</sup>
                    &nbsp; Fotos
                  </div>
                </>
              )}
              {lang === Language.FR && (
                <>
                  <div>
                    Identification rapide du problème grâce aux photos à 360
                    <sup>o</sup>&nbsp;haute résolution
                  </div>
                </>
              )}
              {lang === Language.EN && (
                <>
                  <div>
                    Quick problem identification thanks to the high-resolution
                    360
                    <sup>o</sup>&nbsp;photos
                  </div>
                </>
              )}
            </li>
            <li>
              {lang === Language.DE && <>Zeit- und Kostensparend</>}
              {lang === Language.FR && <>Gain de temps et d'argent</>}
              {lang === Language.EN && <>Saves time and money</>}
            </li>
            <li>
              {lang === Language.DE && (
                <>
                  Oft entspricht das Endresultat nicht den Plänen. Wir nehmen
                  die Baustelle so auf wie sie auch gebaut wurde
                </>
              )}
              {lang === Language.FR && (
                <>
                  Souvent, le résultat final ne correspond pas aux plans. Nous
                  enregistrons le chantier tel qu'il a été construit
                </>
              )}
              {lang === Language.EN && (
                <>
                  Often the end result does not correspond to the plans. We
                  record the building site as it is built
                </>
              )}
            </li>
            <li>
              {lang === Language.DE && (
                <>
                  Sie tun mit uns etwasfür die Umweltund sparen unmengen an
                  Papier
                </>
              )}
              {lang === Language.FR && (
                <>
                  Avec nous, vous faites un geste pour l'environnement et
                  économisez d'énormes quantités de papier
                </>
              )}
              {lang === Language.EN && (
                <>
                  You do something for the environment with us and save vast
                  amounts of paper
                </>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Benefits;
