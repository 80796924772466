import React from "react";

type Props = {
  embedId: string;
};

function Youtube(props: Props): JSX.Element {
  const { embedId } = props;

  return (
    <div className="video-responsive w-full h-[13rem] sm:h-[24rem] md:h-[32rem] lg:h-[35rem] xl:h-[42rem] 2xl:h-[56rem]">
      <iframe
        className="w-full h-full"
        src={`https://www.youtube.com/embed/${embedId}?loop=1&playlist=${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="3Sixty Presentation"
      />
    </div>
  );
}

export default Youtube;
