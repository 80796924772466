import React from "react";
import { Language, useLanguageContext } from "../../Context/LanguageContext";
import "./Header.scss";

function Header(): JSX.Element {
  const { lang } = useLanguageContext();
  const jumpTo = (id: string) => {
    var el = document.getElementById(id);
    if (!el) return;
    el.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <header
      className="navigation flex self-end justify-end text-xl"
      id="header"
    >
      <span onClick={() => jumpTo("idea")}>
        {lang === Language.DE && "DIE IDEE"}
        {lang === Language.FR && "L'IDÉE"}
        {lang === Language.EN && "THE IDEA"}
      </span>
      <span onClick={() => jumpTo("benefits")}>
        {lang === Language.DE && "DIE IDEE"}
        {lang === Language.FR && "AVANTAGES"}
        {lang === Language.EN && "ADVANTAGES"}
      </span>
      <span onClick={() => jumpTo("who")}>
        {lang === Language.DE && "FÜR WEN?"}
        {lang === Language.FR && "POUR QUI?"}
        {lang === Language.EN && "FOR WHOM?"}
      </span>
      <span onClick={() => jumpTo("services")}>
        {lang === Language.DE && "DIENSTLEISTUNGEN"}
        {lang === Language.FR && "SERVICES"}
        {lang === Language.EN && "SERVICES"}
      </span>
      <span onClick={() => jumpTo("footer")}>
        {lang === Language.DE && "KONTAKT"}
        {lang === Language.FR && "CONTACT"}
        {lang === Language.EN && "CONTACT"}
      </span>
    </header>
  );
}

export default Header;
